@use './../bootstrap/variables' as *;

.djangocms-newsblog-content {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    margin-left: auto;
    margin-right: auto;
    padding-left: calc(var(--bs-gutter-x)*0.5);
    padding-right: calc(var(--bs-gutter-x)*0.5);
    width: 100%;
}

@media (min-width: 576px) {
    .djangocms-newsblog-content {
        max-width: 540px;
    }
}

@media (min-width: 768px) {
    .djangocms-newsblog-content {
        max-width: 720px;
    }
}

@media (min-width: 992px) {
    .djangocms-newsblog-content {
        max-width: 960px;
    }
}

@media (min-width: 1450px) {
    .djangocms-newsblog-content {
        max-width: 1400px;
    }
}

// row
.aldryn.aldryn-newsblog.aldryn-newsblog-articles,
.aldryn-newsblog-latest-articles,
.aldryn-newsblog-list,
.djangocms-newsblog-article-list {
    margin-left: calc(-0.5*var(--bs-gutter-x));
    margin-right: calc(-0.5*var(--bs-gutter-x));

    > article {
        max-width: 100%;
        padding-left: calc(var(--bs-gutter-x) * 0.5);
        padding-right: calc(var(--bs-gutter-x) * 0.5);

        > h2 {
            font-size: 0.85rem;
        }

        .date {
            margin-bottom: 0.5rem;
        }

        .lead {
            font-size: 1rem;

            p {
                margin-bottom: 0;
            }
        }

        .tags {
            margin: 0;
        }
    }
}


.djangocms-newsblog-article-list {
    > article {
        max-width: 100%;
        border-radius: 8px;
        border: 1px solid $border;
        padding: 1.5rem 2rem;
        margin: auto 0.5rem 1.5rem;

        > h2 {
            font-size: 1.4rem;
            margin-bottom: 0;
        }

        .lead {
            font-size: 1rem;

            p {
                margin-bottom: 0;
            }
        }
    }
}

.aldryn-newsblog-latest-articles {
    > article {
        > h2 {
            font-size: 0.85rem;
        }

        .date {
            margin-bottom: 0;
            font-size: 0.85rem;
        }

        .lead {
            font-size: 0.85rem;

            p {
                margin-bottom: 0;
            }
        }

        .tags {
            margin: 0;
        }
    }
}
