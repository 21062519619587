@use './../bootstrap/variables' as *;

.navbar-control {
    @media (min-width: 992px) {
        display: none;
    }
}

.navbar-content {
    background-color: $white;

    @media (max-width: 991px) {
        box-shadow: 0 1rem 1rem rgba(0, 0, 0, 0.15);
        color: $dark;
        opacity: 0;
        position: absolute;
        transform: scaleY(0);
        transform-origin: top;
        transition: all 0.15s ease-in-out;
        width: 100%;
        z-index: 10;
    }

    @media (min-width: 992px) {
        padding: 14px 0;
    }

    a {
        color: $dark;
    }

    .logo {
        @media (max-width: 991px) {
            display: none;
        }
    }
}

.show-body {
    .navbar-content {
        @media (max-width: 991px) {
            opacity: 1;
            transform: scaleY(1);
            transform-origin: top;
        }
    }

    .navbar-control {
        position: relative;
        z-index: 10;
    }
}

#toggleMenu {
    cursor: pointer;
    display: block;
    height: 50px;
    position: relative;
    width: 50px;

    @media (min-width: 992px) {
        display: none;
    }
}

.menu-icon {
    bottom: 0;
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    top: 0;
}

.logo {
    height: 24px;

    @media (min-width: 992px) {
        height: 40px;
        padding-right: 2rem;
    }
}

#closeMenu {
    transform: scale(0);
    transition: all 0.15s ease-in-out;
}

#openMenuBlack {
    transform: scale(1);
    transition: all 0.15s ease-in-out;
}

.show-body {
    #openMenuBlack {
        transform: scale(0);
    }

    #closeMenu {
        transform: scale(1);
    }
}

.is-homepage {
    .navbar-content,
    .navbar-control {
        background-color: $light;
    }
}
